// 
// _table.scss
// 
.table {
    >thead {
        border-color: $table-border-color;
    }
    >:not(:first-child) {
        border-top-width: $table-border-width;
    }
}

.table-borders {
    th, td{
        border: 1px $gray-400 solid;   
     }
}


.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

// card table

.table-card{
    margin: 0.55rem (-$card-spacer-x);

    th,
    td {

        &:first-child{
            padding-left: 16px;
        }

        &:last-child{
            padding-right: 16px;
        }
    }

    .table > :not(:first-child) {
        border-top-width: $table-border-width;
    }
}

@each $color,
$value in $theme-colors {
    .border-#{$color} {
        &.table {
            > thead {
                border-color: $value !important;
            }
        }
    }
    .table-#{$color} {
        &.table {
            > thead {
                border-bottom-color:lighten($value, 10%) !important;
            }
        }
    }
}

.table>:not(caption)>*>* {
    color: var(--vz-table-color-state), var(--vz-table-color-type, var(--vz-table-color));
}

.table {
    .form-check {
        padding-left: 0px;
        margin-bottom: 0px;
        .form-check-input {
            margin-left: 0px;
            margin-top: 0px;
            float: none;
            vertical-align: middle;
        }
    }
    .sort {
        position: relative;
        &::before {
            content: "\f035d";
            position: absolute;
            right: 0.5rem;
            top: 18px;
            font-size: 0.8rem;
            font-family: "Material Design Icons";
        }
        &::after {
            position:absolute;
            right: 0.5rem;
            content: "\f0360";
            font-family: "Material Design Icons";
            font-size: 0.8rem;
            top: 12px;
        }
    }
}

.table-fixed {
    table-layout: fixed;
    width: 100%; /* Ensure the table spans the container width */
  }
  
  .table-fixed th,
  .table-fixed td {
    width: 25%; /* Equal width for 4 columns, adjust for more/less columns */
    text-align: center; /* Optional */
  }