// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} * 0.5);
    position: absolute;
    right: 0;
    color: var(--#{$prefix}footer-color);
    left: var(--#{$prefix}vertical-menu-width);
    height: $footer-height;
    background-color: var(--#{$prefix}footer-bg);

    @media (max-width: 991.98px) {
        left: 0;
    }
}

[data-layout="vertical"] {

    &:is([data-sidebar-size="sm"], [data-sidebar-size="sm-hover"]) {
        .footer {
            left: var(--#{$prefix}vertical-menu-width-sm);

            @media (max-width: 767.98px) {
                left: 0;
            }
        }
    }
    &[data-sidebar-size="md"] {
        .footer {
            left: var(--#{$prefix}vertical-menu-width-md);

            @media (max-width: 991.98px) {
                left: 0;
            }
        }
    }

}


[data-layout="vertical"][data-layout-style="detached"] {

    @media (min-width: 1024.1px) {
        .footer {
            left: 0 !important;
            background-color: transparent;
        } 
    }
}
